import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSales(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/sales`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchSale(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/sale/${id}/details`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        changeSaleState(ctx, { id, stateId }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_HOST}/api/dashboard/sale/${id}/change-state`, { stateId })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}