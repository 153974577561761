<template>
    <div>
        <b-col>
            <b-card
                no-body
            >
                <b-card-header>
                    <b-row align-h="between" class="w-100">
                        <b-col cols="auto">DETALLE DE LA VENTA: {{ saleData.code  }}</b-col>
                        <b-col cols="auto">{{ saleData.createdAt ? formatDate(saleData.createdAt, 'DD/MM/YYYY HH:mm:ss') : '---' }}</b-col>
                    </b-row>
                </b-card-header>
                <b-card-body>
                    <b-row>

                        <!-- User Data: Code -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="CÓDIGO DE LA VENTA"
                                label-for="code"
                            >
                                <b-form-input
                                    id="code"
                                    :value="saleData.code"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>
                        
                        <!-- User Data: Names -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="NOMBRE(S)"
                                label-for="names"
                            >
                                <b-form-input
                                    id="names"
                                    :value="saleData.user_data.names"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Surnames -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="APELLIDO(S)"
                                label-for="surnames"
                            >
                                <b-form-input
                                    id="surnames"
                                    :value="saleData.user_data.surnames"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Address -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="DIRECCIÓN"
                                label-for="address"
                            >
                                <b-form-input
                                    id="address"
                                    :value="saleData.user_data.address"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Housing Type -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="TIPO DE DIRECCIÓN"
                                label-for="housing_type"
                            >
                                <b-form-input
                                    id="housing_type"
                                    :value="saleData.user_data.housing_type"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Department -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="DEPARTAMENTO"
                                label-for="department"
                            >
                                <b-form-input
                                    id="department"
                                    :value="saleData.user_data.department"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Province -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="PROVINCIA"
                                label-for="province"
                            >
                                <b-form-input
                                    id="province"
                                    :value="saleData.user_data.province"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: District -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="DISTRITO"
                                label-for="district"
                            >
                                <b-form-input
                                    id="district"
                                    :value="saleData.user_data.district"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Postal Code -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="CÓDIGO POSTAL"
                                label-for="postcode"
                            >
                                <b-form-input
                                    id="postcode"
                                    :value="saleData.user_data.postcode"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Cellphone -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="TELÉFONO CELULAR"
                                label-for="phone"
                            >
                                <b-form-input
                                    id="phone"
                                    :value="saleData.user_data.phone"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: DNI -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="DNI"
                                label-for="dni"
                            >
                                <b-form-input
                                    id="dni"
                                    :value="saleData.user_data.dni"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Email -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="CORREO ELECTRÓNICO"
                                label-for="email"
                            >
                                <b-form-input
                                    id="email"
                                    :value="saleData.user_data.email"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Payment Method -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="MÉTODO DE PAGO"
                                label-for="payment_method"
                            >
                                <b-form-input
                                    id="payment_method"
                                    :value="saleData.payment_method ? saleData.payment_method.name : '---'"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Delivery Type -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="TIPO DE ENTREGA"
                                label-for="delivery_type"
                            >
                                <b-form-input
                                    id="delivery_type"
                                    :value="saleData.delivery_type ? saleData.delivery_type.name : '---'"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Discount Coupon -->
                        <b-col
                            md="6"
                            xl="4"
                        >
                            <b-form-group
                                label="CUPÓN DE DESCUENTO"
                                label-for="discount_coupon"
                            >
                                <b-form-input
                                    id="discount_coupon"
                                    :value="saleData.discountCoupon ? saleData.discountCoupon : '---'"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                        <!-- User Data: Reference -->
                        <b-col
                            md="12"
                        >
                            <b-form-group
                                class="mb-0"
                                label="INFORMACIÓN ADICIONAL"
                                label-for="additional_information"
                            >
                                <b-form-textarea
                                    id="additional_information"
                                    rows="4"
                                    :value="saleData.user_data.additional_information"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>
                        
                    </b-row>
                </b-card-body>
            </b-card>

            <hr>

            <b-row class="mb-2 statistic-cards">
                <b-col
                    xl="2"
                    md="4"
                    sm="6"
                >
                    <statistic-card-vertical
                        hide-chart
                        color="info"
                        icon="StarIcon"
                        :statistic="`S/ ${parseFloat(saleData.amount.$numberDecimal).toFixed(2)}`"
                        statistic-title="MONTO"
                    />
                </b-col>

                <b-col
                    xl="2"
                    md="4"
                    sm="6"
                >
                    <statistic-card-vertical
                        hide-chart
                        color="warning"
                        icon="HeartIcon"
                        :statistic="`S/ ${parseFloat(saleData.subTotal.$numberDecimal).toFixed(2)}`"
                        statistic-title="SUB-TOTAL"
                    />
                </b-col>

                <b-col
                    xl="2"
                    md="4"
                    sm="6"
                >
                    <statistic-card-vertical
                        hide-chart
                        color="danger"
                        icon="AnchorIcon"
                        :statistic="`S/ ${parseFloat(saleData.igv.$numberDecimal).toFixed(2)}`"
                        statistic-title="IGV"
                    />
                </b-col>

                <b-col
                    xl="2"
                    md="4"
                    sm="6"
                >
                    <statistic-card-vertical
                        hide-chart
                        color="primary"
                        icon="TruckIcon"
                        :statistic="`${ saleData.delivery ? 'S/'+parseFloat(saleData.delivery.$numberDecimal).toFixed(2) : '0.00' }`"
                        statistic-title="DELIVERY"
                    />
                </b-col>

                <b-col
                    xl="2"
                    md="4"
                    sm="6"
                >
                    <statistic-card-vertical
                        hide-chart
                        color="danger"
                        icon="TagIcon"
                        :statistic="`${ saleData.discount ? 'S/'+parseFloat(saleData.discount.$numberDecimal).toFixed(2) : '0.00' }`"
                        statistic-title="DESCUENTO"
                    />
                </b-col>

                <b-col
                    xl="2"
                    md="4"
                    sm="6"
                >
                    <statistic-card-vertical
                        hide-chart
                        color="success"
                        icon="DollarSignIcon"
                        :statistic="`S/ ${parseFloat(saleData.totalAmount.$numberDecimal).toFixed(2)}`"
                        statistic-title="MONTO TOTAL"
                    />
                </b-col>
            </b-row>

            <b-card
                no-body
            >
                <b-card-body>
                    <div class="d-flex justify-content-end flex-wrap">

                        <b-col cols="5" class="px-0">
                            <!-- filter -->
                            <b-form-group
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filterInput"
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar producto"
                                    />
                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                        >
                                            Limpiar
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                    </div>
                </b-card-body>

                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >

                    <template #cell(id)="data">
                        {{ data.index + 1 }}
                    </template>

                    <template #cell(price)="data">
                        {{ `S/. ${(data.item.price).toFixed(2)}` }}
                    </template>

                </b-table>

                <b-card-body class="d-flex justify-content-end flex-wrap pt-0">

                    <!-- pagination -->
                    <div>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                        >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                        </b-pagination>
                    </div>

                </b-card-body>
            </b-card>

        </b-col>
    </div>
</template>

<script>
    
    import { BButtonGroup, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox, BCard, BFormTextarea, BCardText } from 'bootstrap-vue';
    import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
    import moment from 'moment';

    export default {
        components: {
            StatisticCardVertical,
            BCard,
            BFormTextarea,
            BButtonGroup,
            BButton,
            BRow,
            BCol,
            BCardText,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm
        },
        props: {
            saleData: {
                type: Object,
                required: true
            }
        },
        data (){
            return {
                perPage: 100,
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: ['name'],
                fields: [
                    { key: 'id', label: '#', sortable: false },
                    { key: 'name', label: 'NOMBRE DEL PRODUCTO', sortable: true },
                    { key: 'qty', label: 'CANTIDAD', sortable: true },
                    { key: 'price', label: 'PRECIO (S/)', sortable: true }
                ],
                items: []
            }
        },
        computed: {
            sortOptions() {
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            }
        },
        mounted() {
            this.items = this.saleData.products;
            this.totalRows = this.items.length;
        },
        methods: {
            formatDate (date, format){
                return moment(date).format(format);
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            }
        }
    }

</script>

<style scoped>

    .card {
        border-radius: 5px;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.5) !important;
        border: 1px solid #9F5735;
        margin-bottom: 0;
    }

    .statistic-cards .card {
        border: 1px solid #36694B;
    }

    .card-header {
        background-color: #9F5735;
        color: #FFF;
        font-weight: bold;
    }

    .card-body {
        padding-top: 1.5rem !important;
    }

    .card-body input,
    .card-body textarea {
        font-weight: bold;
    }

</style>