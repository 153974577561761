
<template>
    <component :is="saleData === undefined ? 'div' : 'b-card'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="saleData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos de la venta</h4>
            <div class="alert-body">
                No se encontró ninguna venta con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'sales'}"
                >
                    Lista de ventas
                </b-link>
                para buscar otra venta.
            </div>
        </b-alert>

        <b-tabs
            v-if="saleData"
            pills
        >
      
            <!-- Tab: Information -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información</span>
                </template>
                <sale-details-tab-information
                    class="mt-2 pt-75"
                    :sale-data="saleData"
                />
            </b-tab>
      
        </b-tabs>
    </component>
</template>

<script>

    import SaleDetailsTabInformation from './SaleDetailsTabInformation.vue';
    import {BAlert, BCard, BLink, BTab, BTabs} from 'bootstrap-vue';
    import {onUnmounted, ref} from '@vue/composition-api';
    import saleStoreModule from '../saleStoreModule';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,
    
            SaleDetailsTabInformation
        },
        setup() {
            const saleData = ref(null);

            const SALE_APP_STORE_MODULE_NAME = 'app-sale';

            // Register module
            if (!store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.registerModule(SALE_APP_STORE_MODULE_NAME, saleStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.unregisterModule(SALE_APP_STORE_MODULE_NAME)
            });

            store.dispatch('app-sale/fetchSale', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        saleData.value = undefined
                    } else {
                        saleData.value = response.data;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        saleData.value = undefined
                    }
                });

            return {
                saleData
            }
        }
    }

</script>